import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { PaginationService } from 'src/app/modules/healthcare/services/pagination.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { finalize, debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CellClickedEvent } from 'ag-grid-community';

@Component({
	selector: 'app-hospital-physician-details',
	templateUrl: './physician-details.component.html',
	styleUrls: ['./physician-details.component.css']
})
export class PhysicianDetailsComponent implements OnInit {
	constructor(
		private amplizService: AmplizService,
		private pagerservice: PaginationService,
		private messageService: MessageService,
		private dialog: MatDialog,
		private router: Router
	) {}
	@Input() hospitalID: string = '';
	@Input() hospitalName: string = '';
	totalPhysicians: any = new BehaviorSubject<any>(0);
	dataSource: any = [];

	paramsData: any;
	context: any;
	columnDefs: any;
	gridApi: any;
	gridColumnApi: any;
	pager: any = { currentPage: 1, offset: 10, totalPages: 1 };

	hospitalPhysicianParameter: any = {};
	physicianDownload: any = {};

	noResult: boolean = false;
	loading: boolean = false;
	filterLoader: any = new BehaviorSubject<boolean>(false);

	dialogRef: any;
	filterObj: any = {
		fullName: '',
		npiNumber: [],
		specialty: [],
		experience: [],
		state: [],
		city: []
	};

	specialityControl = new FormControl();
	specialityList: any = [];

	experienceControl = new FormControl();
	experienceList: any = [];

	npiNumberControl = new FormControl();
	npiNumberList: any = [];

	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;

	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getPhysiciansData();
	}

	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}

	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalPhysicians
			: pageCount > this.totalPhysicians
			? this.totalPhysicians
			: pageCount;
	}
	ngOnInit(): void {
		this.getPhysiciansData();
		this.context = {
			componentParent: this
		};
		this.columnDefs = [
			{
				headerName: 'Name',
				field: 'fullName',
				filter: false,
				autoHeight: true,
				width: 'auto',
				onCellClicked: (event: CellClickedEvent) => {
					this.onCellClicked(event);
				},
				cellStyle: { color: '#2d8cff', cursor: 'pointer' }
			},
			{
				headerName: 'NPI',
				field: 'npiNumber',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: true,
				flex: 1
			},
			{
				headerName: 'Specialty',
				field: 'specialty',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: true,
				flex: 2
			},
			{
				headerName: 'Conditions Treat',
				field: 'experience',
				autoHeight: true,
				flex: 2,
				suppressSizeToFit: true
			},
			{
				headerName: 'Practice Location',
				field: 'location',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: true,
				flex: 1
			}
		];
		this.getFilters();
	}

	onGridReady(params, dataSource?: any) {
		this.paramsData = params;
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.paramsData.api.setRowData(dataSource);
	}

	getPhysiciansData() {
		this.filterLoader.next(true);
		const offset = (this.pager.currentPage - 1) * this.pager.offset;
		this.hospitalPhysicianParameter.offest = offset;
		this.hospitalPhysicianParameter.count = this.pager.offset;
		this.hospitalPhysicianParameter.hospitalId = this.hospitalID;

		this.hospitalPhysicianParameter.fullname = this.filterObj.fullName || '';
		this.hospitalPhysicianParameter.npiNumber = this.filterObj.npiNumber || '';
		this.hospitalPhysicianParameter.state = this.filterObj.state.map((item) => item.state) || '';
		this.hospitalPhysicianParameter.city = this.filterObj.city.map((item) => item.city) || '';
		this.hospitalPhysicianParameter.specialty = this.filterObj.specialty || '';
		this.hospitalPhysicianParameter.experience = this.filterObj.experience || '';

		this.amplizService.getPhysicianListForHospital(this.hospitalPhysicianParameter).subscribe(
			(res: any) => {
				this.totalPhysicians.next(res.total_result);
				const updatedData = res.physicianInfoList.map((item) => ({
					...item,
					experience: item.experience.length > 0 ? item.experience[0] : ''
				}));

				this.paramsData.api.setRowData(updatedData);

				this.pager = {
					...this.pager,
					totalPages: Math.ceil(this.totalPhysicians / this.pager.offset)
				};
				this.filterLoader.next(false);
			},
			(err) => {
				this.filterLoader.next(false);
			}
		);
	}

	downloadCSV() {
		this.loading = true;
		this.physicianDownload.hospitalId = this.hospitalID;
		this.physicianDownload.fullname = this.filterObj.fullName || '';
		this.physicianDownload.npiNumber = this.filterObj.npiNumber || '';
		this.physicianDownload.state = this.filterObj.state.map((item) => item.state) || '';
		this.physicianDownload.city = this.filterObj.city.map((item) => item.city) || '';
		this.physicianDownload.specialty = this.filterObj.specialty || '';
		this.physicianDownload.experience = this.filterObj.experience || '';
		this.amplizService.downloadPhysicianForHospital(this.physicianDownload).subscribe(
			(res: string) => {
				const csvdata = 'data:text/csv;charset=utf-8,' + res;
				var encodedUri = encodeURI(csvdata);

				const anchor = document.createElement('a');
				anchor.href = encodedUri;
				anchor.download = `${this.hospitalName}_HCPs`;
				anchor.click();
				anchor.remove();

				setTimeout(() => {
					this.loading = false;
				}, 200);
				this.messageService.display(true, 'Downloaded Successfully', 'success');
			},
			(error) => {
				console.error(error);
				setTimeout(() => {
					this.loading = false;
				}, 200);
				this.messageService.displayError(true, 'Error while downloading', 'error');
			}
		);
	}

	openFilters() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '500px',
			width: '800px',
			disableClose: false
		});
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}

	clearFilters() {
		this.filterObj = {
			fullName: '',
			npiNumber: [],
			specialty: [],
			experience: [],
			state: [],
			city: []
		};
		this.getPhysiciansData();
	}

	closeFilters() {
		this.dialogRef.close();
	}

	addFilter(event: any, type: string) {
		if (!this.filterObj[type].includes(event.option.value)) {
			this.filterObj[type].push(event.option.value);
			this.getPhysiciansData();
		}
	}

	getSpeciality(event: any) {
		this.filterObj.speciality = event;
		this.getPhysiciansData();
	}

	getFilters() {
		this.specialityControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService
					.getSpecialityList({
						searchPhrase: value,
						previousSearchSpeciality: []
					})
					.subscribe((response) => {
						this.specialityList = response.specialityAll;
					});
			} else {
				const temp: any = [];
				this.specialityList = temp;
			}
		});
		this.experienceControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService.getExperienceList({ searchPhase: value }).subscribe((response) => {
					this.experienceList = response.experienceList;
				});
			} else {
				const temp: any = [];
				this.experienceList = temp;
			}
		});
		this.npiNumberControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 1) {
				this.amplizService.getNPINumberList({ searchPhase: value }).subscribe((response) => {
					this.npiNumberList = response.npiNumberList;
				});
			} else {
				const temp: any = [];
				this.npiNumberList = temp;
			}
		});
	}

	removeFilter(type: string, value: string) {
		this.filterObj[type] = this.filterObj[type].filter((item: any) => item !== value);
		this.getPhysiciansData();
	}

	clear(type: string) {
		this.filterObj[type] = [];
		this.getPhysiciansData();
	}

	onCellClicked(event) {
		this.router.navigate([], { queryParamsHandling: 'preserve' }).then((result) => {
			window.open(`/physicianOverview/${event.data.physicianId}`, '_blank');
		});
	}
}
