import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';
@Component({
	selector: 'app-hospital-alliances',
	templateUrl: './hospital-alliances.component.html',
	styleUrls: ['./hospital-alliances.component.css']
})
export class HospitalAlliancesComponent implements OnInit {
	constructor(private amplizService: AmplizService) {}
	@Input() hospitalID: any;
	@Input() hospitalName: any;
	hospitalAlliancesList: any = [];
	loading: boolean = false;
	ngOnInit(): void {
		this.getHospitalAlliances();
	}

	getHospitalAlliances() {
		if (JSON.parse(sessionStorage.getItem('hospitalAlliancesList')) == null) {
			this.amplizService.getHospitalAlliances(this.hospitalID).subscribe((res: any) => {
				this.hospitalAlliancesList = res.hospitalAlliances;
				delete this.hospitalAlliancesList['IDN Parent ID'];
				delete this.hospitalAlliancesList['IDN ID'];
				delete this.hospitalAlliancesList['HospitalID'];
				sessionStorage.setItem('hospitalAlliancesList', JSON.stringify(this.hospitalAlliancesList));
			});
		} else {
			this.hospitalAlliancesList = JSON.parse(sessionStorage.getItem('hospitalAlliancesList'));
		}
	}

	downloadPdf() {
		this.loading = true;
		this.amplizService.hospitalAlliancePdfDownload(this.hospitalID).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_Alliances.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
