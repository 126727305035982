import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
@Component({
	selector: 'app-hospital-finance-details',
	templateUrl: './hospital-finance-details.component.html',
	styleUrls: ['./hospital-finance-details.component.css']
})
export class HospitalFinanceDetailsComponent implements OnInit {
	@Input() hospitalID: any;
	@Input() hospitalName: any;
	loading: boolean = false;
	financialYear = ['2023'];
	selectedYear: any = '2023';
	financialDetailsList: any;
	noData: boolean = false;

	constructor(private amplizService: AmplizService, private messageService: MessageService) {}

	ngOnInit(): void {
		this.getFinancialDetails();
	}

	selectYear(year) {
		this.selectedYear = year;
		this.getFinancialDetails();
	}

	getFinancialDetails() {
		this.amplizService
			.getHospitalFinancialStats(this.hospitalID, this.selectedYear.toString())
			.subscribe(
				(res) => {
					const selectedYearInString = this.selectedYear.toString();

					if (res?.hospitalFinancial.hasOwnProperty(selectedYearInString)) {
						this.financialDetailsList = res.hospitalFinancial[selectedYearInString];
					}
				},
				(err) => {
					console.log('NO DATA');
				}
			);
	}

	downloadPdf() {
		this.loading = true;
		this.amplizService.hospitalFinancePdfDownload(this.hospitalID, this.selectedYear).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_Finance.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				this.messageService.displayError(true, err.error);
				console.log('ERR', err);
			}
		);
	}
}
