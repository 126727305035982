<div class="main-header">
	<div class="action-container">
		<button class="filter-btn" (click)="openFilters()">
			Filters
			<svg
				width="14"
				height="14"
				viewBox="0 0 13 13"
				fill="#fff"
				xmlns="http://www.w3.org/2000/svg"
				class="ml-2"
			>
				<path
					d="M11.9167 1.625H1.08337L5.41671 6.74917V10.2917L7.58337 11.375V6.74917L11.9167 1.625Z"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</button>
		<button class="download-btn" (click)="downloadCSV()">
			<span *ngIf="!loading">Download CSV</span>
			<div *ngIf="loading" class="bars"></div>
		</button>
	</div>
	<div class="paginationWrapper">
		<div class="paginationWrapper" *ngIf="totalPhysicians.value > 0">
			<ul class="pagination">
				<li
					[ngClass]="{
						disabled: pager.currentPage === 1
					}"
				>
					<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
						><i class="fa fa-chevron-left" aria-hidden="true"></i
					></a>
					<a
						*ngIf="pager.currentPage !== 1"
						(click)="onPaginationClick(pager.currentPage - 1)"
						style="border-radius: 5px 0 0 5px !important"
						><i class="fa fa fa-chevron-left" aria-hidden="true"></i
					></a>
				</li>
				<li
					[ngClass]="{
						disabled: pager.currentPage === pager.totalPages
					}"
				>
					<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
						><i class="fa fa-chevron-right" aria-hidden="true"></i
					></a>
					<a
						*ngIf="pager.currentPage !== pager.totalPages"
						(click)="onPaginationClick(pager.currentPage + 1)"
						style="border-radius: 0 5px 5px 0 !important"
						><i class="fa fa-chevron-right" aria-hidden="true"></i
					></a>
				</li>
			</ul>
			<div class="pull-right paginationInfo">
				{{ offset }}-{{ count }} of
				{{ totalPhysicians.value }}
			</div>
		</div>
	</div>
</div>
<div class="table-container">
	<div>
		<ag-grid-angular
			style="width: 100%; height: 500px"
			class="ag-theme-material"
			(gridReady)="onGridReady($event)"
			#agGrid
			[columnDefs]="columnDefs"
			[animateRows]="true"
			[paginationPageSize]="10"
		>
		</ag-grid-angular>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="filterLoader"
		[totalRecords]="totalPhysicians.value"
		(clear)="clearFilters()"
		(applyFilter)="closeFilters()"
		(closeFilter)="closeFilters()"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<div class="dialog-flex">
			<app-more-filter-expand-item
				title="Speciality"
				class="dialog-flex-container"
				[selectedData]="filterObj.specialty"
				(clear)="clear('specialty')"
			>
				<mat-form-field [style.width.px]="220">
					<mat-chip-list #includeSpecialityList aria-label="Include specialty input">
						<mat-basic-chip
							*ngFor="let spec of filterObj.specialty"
							[removable]="true"
							(removed)="removeFilter('specialty', spec)"
						>
							{{ spec }}
							<mat-icon matChipRemove>close</mat-icon>
						</mat-basic-chip>
						<input
							#d_includedSpeciality
							id="includeSpecialityInput"
							aria-label="Include Speciality"
							matInput
							placeholder=""
							[formControl]="specialityControl"
							[matAutocomplete]="includeSpecAuto"
							floatPlaceholder="never"
							[matChipInputFor]="includeSpecialityList"
						/>
					</mat-chip-list>
					<mat-autocomplete
						#includeSpecAuto="matAutocomplete"
						(optionSelected)="addFilter($event, 'specialty'); d_includedSpeciality.value = ''"
						class="app-autocomplete"
					>
						<mat-option *ngFor="let spec of specialityList" [value]="spec">
							{{ spec }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.specialty"
						(onChange)="getPhysiciansData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>

			<app-more-filter-expand-item
				title="Experience"
				class="dialog-flex-container"
				[selectedData]="filterObj.experience"
				(clear)="clear('experience')"
			>
				<mat-form-field [style.width.px]="220">
					<mat-chip-list #experienceChips aria-label="Include speciality input">
						<mat-basic-chip
							*ngFor="let spec of filterObj.experience"
							[removable]="true"
							(removed)="removeFilter('experience', spec)"
						>
							{{ spec }}
							<mat-icon matChipRemove>close</mat-icon>
						</mat-basic-chip>
						<input
							#experienceInput
							id="includeSpecialityInput"
							aria-label="Include Speciality"
							matInput
							placeholder=""
							[formControl]="experienceControl"
							[matAutocomplete]="experienceAuto"
							floatPlaceholder="never"
							[matChipInputFor]="experienceChips"
						/>
					</mat-chip-list>
					<mat-autocomplete
						#experienceAuto="matAutocomplete"
						(optionSelected)="addFilter($event, 'experience'); experienceInput.value = ''"
						class="app-autocomplete"
					>
						<mat-option *ngFor="let spec of experienceList" [value]="spec">
							{{ spec }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.experience"
						(onChange)="getPhysiciansData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>

			<app-more-filter-expand-item
				title="NPI Number"
				class="dialog-flex-container"
				[selectedData]="filterObj.npiNumber"
				(clear)="clear('npiNumber')"
			>
				<mat-form-field [style.width.px]="220">
					<mat-chip-list #npiChips aria-label="Include speciality input">
						<mat-basic-chip
							*ngFor="let spec of filterObj.npiNumber"
							[removable]="true"
							(removed)="removeFilter('npiNumber', spec)"
						>
							{{ spec }}
							<mat-icon matChipRemove>close</mat-icon>
						</mat-basic-chip>
						<input
							#npiNumberInput
							id="includeSpecialityInput"
							aria-label="Include Speciality"
							matInput
							placeholder=""
							[formControl]="npiNumberControl"
							[matAutocomplete]="NpiAuto"
							floatPlaceholder="never"
							[matChipInputFor]="npiChips"
						/>
					</mat-chip-list>
					<mat-autocomplete
						#NpiAuto="matAutocomplete"
						(optionSelected)="addFilter($event, 'npiNumber'); npiNumberInput.value = ''"
						class="app-autocomplete"
					>
						<mat-option *ngFor="let spec of npiNumberList" [value]="spec">
							{{ spec }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.npiNumber"
						(onChange)="getPhysiciansData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>

			<app-location-filter
				class="dialog-flex-container"
				[countryList]="[]"
				(locationValueChanged)="getPhysiciansData()"
				[(selectedStatesFromFilter)]="filterObj.state"
				[(selectedCitiesFromFilter)]="filterObj.city"
			>
			</app-location-filter>
		</div>
	</mat-dialog-content>
</ng-template>
