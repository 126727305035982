<div class="main-header">
	<div class="action-container">
		<button class="download-btn" (click)="downloadPdf()">
			<span *ngIf="!loading">Download PDF</span>
			<div *ngIf="loading" class="bars"></div>
		</button>
	</div>
</div>
<div class="scrollable-container">
	<div class="container">
		<div class="info-card">
			<div class="keyvalue-container">
				<ng-container *ngFor="let stat of hospitalTechIQList | keyvalue">
					<div class="keyvalue">
						<span>{{ stat.key }} </span>
						<p *ngIf="stat.value !== ''">{{ stat.value }}</p>
						<p *ngIf="stat.value === ''" style="color: #919191">--</p>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
