import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { DataService } from '../../services/data.service';
@Component({
	selector: 'app-hospital-overview',
	templateUrl: './hospital-overview.component.html',
	styleUrls: ['./hospital-overview.component.css']
})
export class HospitalOverviewComponent implements OnInit, AfterViewInit {
	hospitalId1: any;
	hospitalId2: string = '';

	hospitalOverview: any = [];
	hospitalOverviewDetails: any = [];
	hospitalOverviewSocials: any = [];

	subscriptions = [];
	subscribed: boolean;
	headerData: string = 'Request Pricing';

	notCorrectDrawer: boolean = false;
	noResult: boolean = false;

	attributes = [
		{
			name: 'Overview',
			active: true
		},
		{
			name: 'HCPs',
			active: false
		},
		{
			name: 'Executives',
			active: false
		},
		{
			name: 'Finance',
			active: false
		},
		{
			name: 'Specification',
			active: false
		},
		{
			name: 'Hospital Stats',
			active: false
		},
		{
			name: 'Alliances',
			active: false
		},
		{
			name: 'Total Stats',
			active: false
		},
		{
			name: 'Value Stats',
			active: false
		},
		{
			name: 'Technology',
			active: false
		},
		{
			name: 'CPT Codes',
			active: false
		},
		{
			name: 'HCPCS Codes',
			active: false
		},
		{
			name: 'ICD 10',
			active: false
		}
	];

	constructor(
		private activatedRoute: ActivatedRoute,
		public amplizService: AmplizService,
		private loaderService: LoaderService,
		private domSanitizer: DomSanitizer,
		private dataService: DataService,
		private router: Router
	) {}

	get hospitalData() {
		return this.hospitalOverviewDetails?.hospitalInfoData || this.hospitalOverviewDetails;
	}

	get getActiveFilter() {
		return this.attributes.find((filter) => filter.active) || null;
	}

	ngOnInit() {}

	get location() {
		return this.hospitalOverview?.Address1 + this.hospitalOverview?.Location;
	}

	get MapUrl() {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(
			`https://www.google.com/maps/embed/v1/place?key=AIzaSyA9MPVyBx9QI03grz7fgaUuLmwcJ8lwd9k&q=${this.location}`
		);
	}

	ngAfterViewInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.hospitalId1 = params.hospitalId1;
		});

		this.getHospitalOverviewData();
		this.getDashboardDetails();
	}

	cancelBtnClick(value: boolean) {
		this.notCorrectDrawer = value;
	}

	getHospitalOverviewData() {
		this.loaderService.display(true);

		this.amplizService.hospitalOverviewDetails(this.hospitalId1).subscribe((res) => {
			this.hospitalOverview = res.hospitalOverview;
			this.hospitalOverviewDetails = res.hospitalOverviewDetails;
			this.hospitalOverviewSocials = res.hospitalSocials;
			this.hospitalId2 = this.hospitalOverviewDetails['Hospital Id'];
			delete this.hospitalOverviewDetails['Cpt Codes'];
			delete this.hospitalOverviewDetails['Hcpcs Codes'];
			delete this.hospitalOverviewDetails['Hospital Id'];
			delete this.hospitalOverviewDetails['Icdten Codes'];
			delete this.hospitalOverviewDetails['Latitude'];
			delete this.hospitalOverviewDetails['Longitude'];
			delete this.hospitalOverviewDetails['Phone Exists'];
			// delete this.hospitalOverviewDetails['Noofexecutives'];
			// delete this.hospitalOverviewDetails['No Of Physician'];

			this.loaderService.display(false);
		});
	}

	async getDashboardDetails() {
		const authToken = await localStorage.getItem('auth_token');
		const refreshToken = await localStorage.getItem('refresh_token');
		if (authToken !== null && refreshToken !== null) {
			this.amplizService.getDashboardDetails().subscribe(
				(res) => {
					this.subscriptions = res.Subscriptions;
					if (this.subscriptions[0].SubscriptionType == 'Free') {
						localStorage.setItem('SubscriptionisActive', 'false');
						this.subscribed = false;

						this.headerData = 'Request Pricing';
					}
					if (this.subscriptions[0].SubscriptionType == 'Paid') {
						localStorage.setItem('SubscriptionisActive', 'true');
						this.subscribed = true;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
				}
			);
		} else {
			this.amplizService.logout();
		}
	}

	openSocial(link: any) {
		window.open(link, '_blank').focus();
	}

	activateFilter(filterName) {
		this.attributes = this.attributes.map((filter) => ({
			...filter,
			active: filter?.name === filterName
		}));
	}

	navigateToExecutive() {
		this.dataService.passHospitalForExecutives(this.hospitalOverview['Hospital Name']);
		this.router.navigate(['executive'], { queryParamsHandling: 'preserve' });
	}
}
