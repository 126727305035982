<div class="main-header">
	<div class="action-container">
		<button class="download-btn" (click)="downloadPdf()">
			<span *ngIf="!loading">Download PDF</span>
			<div *ngIf="loading" class="bars"></div>
		</button>
	</div>
</div>
<div class="scrollable-container">
	<div class="container">
		<div class="info-card">
			<div class="d-flex justify-content-between align-items-center">
				<h3>Summary Scores</h3>
				<mat-form-field appearance="fill">
					<mat-label>Select Year</mat-label>
					<mat-select [(value)]="selectedYear" (selectionChange)="onYearChange($event)">
						<mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="keyvalue-container">
				<ng-container *ngFor="let stat of summaryScores[selectedYear] | keyvalue">
					<div class="keyvalue">
						<span>{{ stat.key }} </span>
						<p *ngIf="stat.value !== ''">{{ stat.value }}</p>
						<p *ngIf="stat.value === ''" style="color: #919191">--</p>
					</div>
				</ng-container>
			</div>
		</div>
		<ng-container *ngFor="let stat of valueStatsList | keyvalue">
			<div class="info-card">
				<h3>{{ stat.key }}</h3>

				<div class="keyvalue-container">
					<ng-container *ngFor="let substat of stat.value | keyvalue">
						<div class="keyvalue">
							<span>{{ substat.key }} </span>
							<p *ngIf="substat.value !== ''">{{ substat.value }}</p>
							<p *ngIf="substat.value === ''" style="color: #919191">--</p>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</div>
