import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';

@Component({
	selector: 'app-hospital-stats',
	templateUrl: './hospital-stats.component.html',
	styleUrls: ['./hospital-stats.component.css']
})
export class HospitalStatsComponent implements OnInit {
	constructor(private amplizService: AmplizService) {}
	@Input() hospitalID: any;
	@Input() hospitalName: any;
	hospitalStatsList: any = [];
	loading: boolean = false;
	ngOnInit(): void {
		this.getHospitalStats();
	}

	getHospitalStats() {
		if (JSON.parse(sessionStorage.getItem('hospitalStats')) == null) {
			this.amplizService.getHospitalStats(this.hospitalID).subscribe((res: any) => {
				this.hospitalStatsList = res.hospitalStats;
				delete this.hospitalStatsList['IDN Parent ID'];
				delete this.hospitalStatsList['IDN ID'];
				delete this.hospitalStatsList['HospitalID'];
				sessionStorage.setItem('hospitalStats', JSON.stringify(this.hospitalStatsList));
			});
		} else {
			this.hospitalStatsList = JSON.parse(sessionStorage.getItem('hospitalStats'));
		}
	}
	downloadPdf() {
		this.loading = true;
		this.amplizService.hospitalStatsPdfDownload(this.hospitalID).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_HospitalStats.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
