<div class="main-header">
	<div class="action-container">
		<button class="filter-btn" (click)="openFilters()">
			Filters
			<svg
				width="14"
				height="14"
				viewBox="0 0 13 13"
				fill="#fff"
				xmlns="http://www.w3.org/2000/svg"
				class="ml-2"
			>
				<path
					d="M11.9167 1.625H1.08337L5.41671 6.74917V10.2917L7.58337 11.375V6.74917L11.9167 1.625Z"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</button>
		<button class="download-btn" (click)="downloadCSV()">
			<span *ngIf="!loading">Download CSV</span>
			<div *ngIf="loading" class="bars"></div>
		</button>
	</div>
	<div class="paginationWrapper">
		<div class="paginationWrapper" *ngIf="totalExecutives.value > 0">
			<ul class="pagination">
				<li
					[ngClass]="{
						disabled: pager.currentPage === 1
					}"
				>
					<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
						><i class="fa fa-chevron-left" aria-hidden="true"></i
					></a>
					<a
						*ngIf="pager.currentPage !== 1"
						(click)="onPaginationClick(pager.currentPage - 1)"
						style="border-radius: 5px 0 0 5px !important"
						><i class="fa fa fa-chevron-left" aria-hidden="true"></i
					></a>
				</li>
				<li
					[ngClass]="{
						disabled: pager.currentPage === pager.totalPages
					}"
				>
					<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
						><i class="fa fa-chevron-right" aria-hidden="true"></i
					></a>
					<a
						*ngIf="pager.currentPage !== pager.totalPages"
						(click)="onPaginationClick(pager.currentPage + 1)"
						style="border-radius: 0 5px 5px 0 !important"
						><i class="fa fa-chevron-right" aria-hidden="true"></i
					></a>
				</li>
			</ul>
			<div class="pull-right paginationInfo">
				{{ offset }}-{{ count }} of
				{{ totalExecutives.value }}
			</div>
		</div>
	</div>
</div>
<div class="table-container">
	<div>
		<ag-grid-angular
			style="width: 100%; height: 500px"
			class="ag-theme-material"
			(gridReady)="onGridReady($event)"
			#agGrid
			[columnDefs]="columnDefs"
			[animateRows]="true"
			[paginationPageSize]="10"
		>
		</ag-grid-angular>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="filterLoader"
		[totalRecords]="totalExecutives.value"
		(clear)="clearFilters()"
		(applyFilter)="closeFilter()"
		(closeFilter)="closeFilter()"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<div class="dialog-flex">
			<app-more-filter-expand-item
				title="Name"
				class="dialog-flex-container"
				[selectedData]="filterObj.fullName"
			>
				<mat-form-field>
					<input
						id="tempPhysicianNameInput"
						#tempPhysicianNameInput
						matInput
						value="{{ filterObj.fullName }}"
						(keyup.enter)="addTempPersonName($event)"
					/>
				</mat-form-field>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.fullName"
						(onChange)="getExecutiveData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>
			<app-more-filter-expand-item
				class="dialog-flex-container"
				title="Keywords/Title"
				[(selectedData)]="filterObj.title"
				[single]="true"
				(omitChange)="getExecutiveData()"
			>
				<app-keyword-input
					title="Keywords/Title"
					[storageKey]="'keyword_executive_Page'"
					[(selectedData)]="filterObj.title"
					(onItemAdd)="getExecutiveData()"
				></app-keyword-input>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.title"
						(onChange)="getExecutiveData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>
			<app-more-filter-expand-item
				class="dialog-flex-container"
				title="Level"
				[(selectedData)]="filterObj.levelID"
				(clear)="clearFilter('levelID')"
				[single]="true"
				itemValue="id"
				itemName="level"
				(omitChange)="getExecutiveData()"
			>
				<app-select-drop-down
					title="Select Level"
					[(list)]="levelListData"
					[(selectedData)]="filterObj.levelID"
					(onItemSelect)="getExecutiveData()"
					[itemName]="'level'"
					[itemValue]="'id'"
					[returnObject]="true"
				></app-select-drop-down>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.levelID"
						(onChange)="getExecutiveData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>
			<app-more-filter-expand-item
				class="dialog-flex-container"
				title="Department"
				[(selectedData)]="filterObj.department"
				(clear)="clearFilter('department')"
				[single]="true"
				(omitChange)="getExecutiveData()"
			>
				<app-select-drop-down
					title="Select Department"
					[(list)]="departmentListData"
					[(selectedData)]="filterObj.department"
					(onItemSelect)="getExecutiveData()"
				></app-select-drop-down>
				<div class="chips-container">
					<app-chip-container
						[(data)]="filterObj.department"
						(onChange)="getExecutiveData()"
					></app-chip-container>
				</div>
			</app-more-filter-expand-item>
			<app-location-filter
				class="dialog-flex-container"
				[countryList]="[]"
				(locationValueChanged)="getExecutiveData()"
				[(selectedStatesFromFilter)]="filterObj.state"
				[(selectedCitiesFromFilter)]="filterObj.city"
			>
			</app-location-filter>
		</div>
	</mat-dialog-content>
</ng-template>
