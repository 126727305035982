<div class="main-container">
	<div class="main-header">
		<div class="finance-filters">
			<div>
				<mat-form-field appearance="fill">
					<mat-label>Select Financial Year </mat-label>
					<mat-select [(value)]="selectedYear">
						<ng-container *ngFor="let year of financialYear">
							<mat-option [value]="year" (click)="selectYear(year)">{{ year }}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="action-container">
			<button class="download-btn" (click)="downloadPdf()">
				<span *ngIf="!loading">Download PDF</span>
				<div *ngIf="loading" class="bars"></div>
			</button>
		</div>
	</div>

	<div class="scrollable-container">
		<div class="container">
			<ng-container *ngFor="let stat of financialDetailsList | keyvalue">
				<div class="info-card">
					<h3>{{ stat.key }}</h3>
					<div class="keyvalue-container">
						<ng-container *ngFor="let substat of stat.value | keyvalue">
							<ng-container>
								<div class="keyvalue">
									<span>{{ substat.key }} </span>
									<p *ngIf="substat.value !== ''">{{ substat.value }}</p>
									<p *ngIf="substat.value === ''" style="color: #919191">--</p>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
