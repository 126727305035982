import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';

@Component({
	selector: 'app-hospital-total-stats',
	templateUrl: './hospital-total-stats.component.html',
	styleUrls: ['./hospital-total-stats.component.css']
})
export class HospitalTotalStatsComponent implements OnInit {
	constructor(private amplizService: AmplizService) {}
	@Input() hospitalID: any;
	@Input() hospitalName: any;
	hospitalTotalStats: any = [];
	loading: boolean = false;
	ngOnInit(): void {
		this.getHospitalTotalStats();
	}

	getHospitalTotalStats() {
		if (JSON.parse(sessionStorage.getItem('hospitalTotalStats')) == null) {
			this.amplizService.getHospitalTotalStats(this.hospitalID).subscribe((res: any) => {
				this.hospitalTotalStats = res.hospitalTotalStats;
				delete this.hospitalTotalStats['IDN Parent ID'];
				delete this.hospitalTotalStats['IDN ID'];
				delete this.hospitalTotalStats['HospitalID'];
				sessionStorage.setItem('hospitalTotalStats', JSON.stringify(this.hospitalTotalStats));
			});
		} else {
			this.hospitalTotalStats = JSON.parse(sessionStorage.getItem('hospitalTotalStats'));
		}
	}
	downloadPdf() {
		this.loading = true;
		this.amplizService.hospitalTotalStatsPdfDownload(this.hospitalID).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_TotalStats.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
