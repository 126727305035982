import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { PaginationService } from 'src/app/modules/healthcare/services/pagination.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CellClickedEvent } from 'ag-grid-community';
@Component({
	selector: 'app-hospital-executive-details',
	templateUrl: './hospital-executive-details.component.html',
	styleUrls: ['./hospital-executive-details.component.css']
})
export class HospitalExecutiveDetailsComponent implements OnInit {
	constructor(
		private amplizService: AmplizService,
		private pagerservice: PaginationService,
		private messageService: MessageService,
		private dialog: MatDialog,
		private router: Router
	) {}
	@Input() hospitalID: string = '';
	@Input() hospitalName: string = '';
	totalExecutives: any = new BehaviorSubject<any>(0);

	dataSource: any = [];

	paramsData: any;
	context: any;
	columnDefs: any;
	gridApi: any;
	gridColumnApi: any;
	pager: any = { currentPage: 1, offset: 10, totalPages: 1 };

	totalSize: any;
	pagedItems: any;
	page: any;
	hospitalPhysicianParameter: any = {};
	totalSearchResult: any = [];
	noResult: boolean = false;
	loading: boolean = false;

	filterLoader: any = new BehaviorSubject<boolean>(false);
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;

	dialogRef: any;
	filterObj: any = {
		fullName: '',
		levelID: [],
		department: [],
		title: [],
		state: [],
		city: []
	};
	levelListData: any = [
		{ id: 1, level: 'C Level' },
		{ id: 2, level: 'VP Level' },
		{ id: 3, level: 'Director' },
		{ id: 4, level: 'Manager' },
		{ id: 5, level: 'Staff' },
		{ id: 6, level: 'Others' }
	];
	departmentListData: any = [];
	executiveDownload: any = {};

	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getExecutiveData();
	}

	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}

	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalExecutives
			: pageCount > this.totalExecutives
			? this.totalExecutives
			: pageCount;
	}
	ngOnInit(): void {
		this.getExecutiveData();
		this.amplizService.getDepartmentList(null).subscribe((response) => {
			this.departmentListData = response.departmentList;
		});
		this.context = {
			componentParent: this
		};
		this.columnDefs = [
			{
				headerName: 'Name',
				field: 'fullName',
				filter: false,
				autoHeight: true,
				flex: 1,
				onCellClicked: (event: CellClickedEvent) => {
					this.onCellClicked(event);
				},
				cellStyle: { color: '#2d8cff', cursor: 'pointer' }
			},
			{
				headerName: 'Title',
				field: 'title',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: true,
				flex: 3
			},
			{
				headerName: 'Department',
				field: 'department',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: false,
				flex: 1
			},
			{
				headerName: 'Seniority',
				field: 'levelID',
				sortingOrder: ['desc', 'asc'],
				autoHeight: true,
				wrapText: true,
				flex: 1
			},
			{
				headerName: 'Location',
				field: 'location',
				autoHeight: true,
				flex: 1,
				suppressSizeToFit: true
			}
		];
	}

	onGridReady(params, dataSource?: any) {
		this.paramsData = params;
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.paramsData.api.setRowData(dataSource);
	}

	openFilters() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '500px',
			width: '800px',
			disableClose: true
		});
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}

	getExecutiveData() {
		this.filterLoader.next(true);
		const offset = (this.pager.currentPage - 1) * this.pager.offset;
		this.hospitalPhysicianParameter.offest = offset;
		this.hospitalPhysicianParameter.count = this.pager.offset;
		this.hospitalPhysicianParameter.hospitalId = this.hospitalID;

		this.hospitalPhysicianParameter.fullName = this.filterObj.fullName || '';
		this.hospitalPhysicianParameter.department = this.filterObj.department || '';
		this.hospitalPhysicianParameter.state = this.filterObj.state.map((item) => item.state) || '';
		this.hospitalPhysicianParameter.city = this.filterObj.city.map((item) => item.city) || '';
		this.hospitalPhysicianParameter.title = this.filterObj.title || '';
		this.hospitalPhysicianParameter.levelID = this.filterObj.levelID.map((item) => item.id) || '';
		this.amplizService.getExecutiveListForHospital(this.hospitalPhysicianParameter).subscribe(
			(res: any) => {
				this.totalExecutives.next(res.total_result);
				this.paramsData.api.setRowData(res.executiveInfoList);

				this.pager = {
					...this.pager,
					totalPages: Math.ceil(this.totalExecutives / this.pager.offset)
				};
				this.filterLoader.next(false);
			},
			(err) => {
				this.filterLoader.next(false);
			}
		);
	}
	downloadCSV() {
		this.loading = true;
		this.executiveDownload.hospitalId = this.hospitalID;
		this.executiveDownload.fullName = this.filterObj.fullName || '';
		this.executiveDownload.department = this.filterObj.department || '';
		this.executiveDownload.state = this.filterObj.state.map((item) => item.state) || '';
		this.executiveDownload.city = this.filterObj.city.map((item) => item.city) || '';
		this.executiveDownload.title = this.filterObj.title || '';
		this.executiveDownload.levelID = this.filterObj.levelID.map((item) => item.id) || '';
		this.amplizService.downloadExecutivesForHospital(this.executiveDownload).subscribe(
			(res: string) => {
				const csvdata = 'data:text/csv;charset=utf-8,' + res;
				var encodedUri = encodeURI(csvdata);

				const anchor = document.createElement('a');
				anchor.href = encodedUri;
				anchor.download = `${this.hospitalName}_Executives`;
				anchor.click();
				anchor.remove();

				setTimeout(() => {
					this.loading = false;
				}, 200);
				this.messageService.display(true, 'Downloaded Successfully', 'success');
			},
			(error) => {
				console.error(error);
				setTimeout(() => {
					this.loading = false;
				}, 200);
				this.messageService.displayError(true, 'Error while downloading', 'error');
			}
		);
	}
	clearFilters() {
		this.filterObj = {
			fullName: '',
			levelID: [],
			department: [],
			title: [],
			state: [],
			city: []
		};
		this.getExecutiveData();
	}

	addTempPersonName(event: any): void {
		this.filterObj.fullName = event.target.value;
		if (event.target.value.length >= 3 || event.target.value.length == 0) {
			this.getExecutiveData();
		}
	}

	clearFilter(type) {
		this.filterObj[type] = [];
		this.getExecutiveData();
	}

	closeFilter() {
		this.dialogRef.close();
	}

	onCellClicked(event) {
		this.router.navigate([], { queryParamsHandling: 'preserve' }).then((result) => {
			window.open(`/executiveOverview/${event.data.executiveId}`, '_blank');
		});
	}
}
